import React from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

import { Icon } from 'ui/atoms'
import { FONTS } from 'constants/fonts'

const StatusesBox = styled.div`
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .status {
    height: 28px;
    border-radius: 4px;
    background-color: #d5d4d9;
    display: flex;
    align-items: center;
    padding: 0 2px;
    width: 47%;
    cursor: pointer;
    color: black;

    &:hover {
      background-color: #ceccd5;
    }

    &.long {
      width: 100%;
      margin-top: 8px;
    }

    &.active {
      background-color: #484b50;

      .text {
        color: white;
      }

      .icon-fire {
        .cls-3 {
          fill: #484b50;
        }

        .cls-4 {
          fill: white;
        }
      }

      .icon-wrapped-home {
        .cls-2 {
          fill: white;
        }
      }

      .icon-link {
        .cls-2 {
          fill: white;
        }
      }

      .icon-eye-crossed {
        .cls-3 {
          fill: white;
        }
      }
    }

    .icon-fire {
      margin-right: 4px;

      .cls-3 {
        fill: #d5d4d9;
      }

      .cls-4 {
        fill: black;
      }
    }

    .icon-wrapped-home {
      margin-right: 4px;

      .cls-1 {
        fill: transparent;
      }

      .cls-2 {
        fill: black;
      }
    }

    .icon-link {
      margin-right: 4px;
      transform: rotate(90deg);

      .cls-2 {
        fill: black;
      }
    }

    .icon-eye-crossed {
      margin-right: 4px;

      .cls-1 {
        fill: transparent;
      }

      .cls-3 {
        fill: black;
      }
    }

    .text {
      margin-top: -2px;
      font-family: ${FONTS.roboto};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }
`


const statuses = [
  {
    name: 'HOT news',
    value: 'hotNews',
    icon: 'fire',
  },
  {
    name: 'На главной',
    value: 'mainRejected',
    icon: 'wrapped-home',
  },
  {
    name: 'Доступ только по ссылке',
    value: 'accessLinkOnly',
    long: true,
    icon: 'link',
  },
  {
    name: 'Снято с публикации',
    value: 'published',
    long: true,
    icon: 'eye-crossed',
  },
]

export const Statuses = ({ activeStatuses, setStatus }) => (
  <StatusesBox>
    {
      statuses.map((status) => (
        <div
          key={status.value}
          className={classNames('status', { long: status.long, active: activeStatuses[status.value] })}
          onClick={() => setStatus(status.value)}
        >
          <Icon type={status.icon} />
          <div className="text">{status.name}</div>
        </div>
      ))
    }
  </StatusesBox>
)
