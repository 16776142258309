import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import queryString from 'query-string'

import { LibraryFilters } from 'features/Library/LibraryFilters'
import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'
import { ArticlesList } from 'features/Library/ArticlesList'
import { ArticlesListPlaceholder } from 'features/Library/ArticlesListPlaceholder'
import { Pagination } from 'ui/molecules'
import { ArticlePreview } from 'features/Preview'
import { articleMock } from 'features/Preview/articleMock'
import { POSTS } from 'api/posts'

const LibraryBox = styled.div`
  display: flex;

  .content {
    padding: 40px;
    max-width: 1300px;
  }

  .search-wrapper {
    height: 30px;
    border-radius: 16px;
    position: relative;

    .icon-zoom {
      position: absolute;
      cursor: pointer;
    }

    .search-input {
      height: 100%;
      border-radius: 16px;
      background-color: #e7e8ea;
      outline: none;
      padding-left: 40px;
      font-family: ${FONTS.roboto};
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      text-align: left;
      color: black;
      box-sizing: border-box;
      border: none;

      &::placeholder {
        font-family: ${FONTS.roboto};
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }
  }

  .empty {
    font-family: ${FONTS.roboto};
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  .pagination-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
`

export const Library = ({ location, history }) => {
  const searchPhrase = queryString.parse(location.search)?.search || ''

  const previewIndexFromLocation = !Number.isNaN(Number(queryString.parse(location.search)?.pi)) ? Number(queryString.parse(location.search)?.pi) : null

  const rubrics = useSelector((state) => state.news.rubrics)

  const [loading, setLoading] = useState(true)
  const [posts, setPosts] = useState([])
  const [postsCount, setPostsCount] = useState(0)
  const [previewIndex, togglePreview] = useState(null)
  const [search, setSearch] = useState(searchPhrase)

  const activeRubrics = [queryString.parse(location.search)?.rubricsId || []].flat()
  const activeAuthors = [queryString.parse(location.search)?.authorsId || []].flat()
  const activeTypes = [queryString.parse(location.search)?.type || []].flat()
  const page = Number(queryString.parse(location.search)?.page) || 1
  const date = queryString.parse(location.search)?.date ? Number(queryString.parse(location.search).date) : 'Последние 7 дней'
  const statuses = {
    hotNews: queryString.parse(location.search)?.hotNews || false,
    mainRejected: queryString.parse(location.search)?.mainRejected || false,
    accessLinkOnly: queryString.parse(location.search)?.accessLinkOnly || false,
    published: queryString.parse(location.search)?.published || false,
  }

  const startDateFromLocation = queryString.parse(location.search)?.dateFrom
  const endDateFromLocation = queryString.parse(location.search)?.dateTo

  const startDate = !startDateFromLocation ? ((new Date()).setDate((new Date()).getDate() - 14)).valueOf() : Number(startDateFromLocation)
  const endDate = !startDateFromLocation ? (new Date()).valueOf() : endDateFromLocation ? Number(endDateFromLocation) : Number(startDateFromLocation)

  async function getPosts() {
    setLoading(true)
    const params = queryString.parse(location.search)

    params.count = 20

    if (!params.page) {
      params.page = 1
    }

    params.dateFrom = startDate
    params.dateTo = endDate

    const response = await POSTS.getPosts(params)

    setPosts(response.data)
    setPostsCount(response.count)

    setLoading(false)
  }

  async function getSearchedPosts() {
    setLoading(true)

    const params = queryString.parse(location.search)

    params.count = 20

    if (!params.page) {
      params.page = 1
    }

    params.search = search

    try {
      const response = await POSTS.search(params)

      setPosts(response.data)
      setPostsCount(response.count)
    } catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  function setLocationParams(name, value, replace) {
    const params = queryString.parse(location.search)

    if (Array.isArray(name)) {
      name.forEach((n, index) => {
        params[n] = value[index]

        if (!value[index] && value[index] !== 0) {
          delete params[n]
        }
      })
    } else {
      params[name] = value

      if (!value && value !== 0) {
        delete params[name]
      }
    }

    if (replace) {
      history.replace(`${location.pathname}?${queryString.stringify(params)}`)

      return
    }

    history.push(`${location.pathname}?${queryString.stringify(params)}`)
  }

  useEffect(() => {
    togglePreview(previewIndexFromLocation)

    if (searchPhrase) {
      getSearchedPosts()

      return
    }

    getPosts()
    setSearch('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, location])

  function onSearch() {
    if (!search) {
      history.push(`${location.pathname}`)

      return
    }

    history.push(`${location.pathname}?search=${search}`)
  }

  function onPreviewOpen(index) {
    if (index === null) {
      setLocationParams('pi', [], true)

      return
    }

    setLocationParams('pi', index)
  }

  const onSelectRubric = (rubric) => {
    const id = rubric._id

    if (activeRubrics.includes(id)) {
      const index = activeRubrics.indexOf(id)

      setLocationParams('rubricsId', [...activeRubrics.slice(0, index), ...activeRubrics.slice(index + 1)])

      return
    }

    setLocationParams('rubricsId', [...activeRubrics, id])
  }

  const onSelectType = (type) => {
    if (activeTypes.includes(type)) {
      const index = activeTypes.indexOf(type)

      setLocationParams('type', [...activeTypes.slice(0, index), ...activeTypes.slice(index + 1)])

      return
    }

    setLocationParams('type', [...activeTypes, type])
  }

  const onSelectStatus = (status) => {
    if (status === 'accessLinkOnly' && !statuses[status]) {
      const keys = Object.keys(statuses).filter((status) => status !== 'accessLinkOnly')

      setLocationParams([status, ...keys], [true, ...keys.map(() => [])])

      return
    }

    if (status === 'published' && !statuses[status]) {
      const keys = Object.keys(statuses).filter((status) => status !== 'published')

      setLocationParams([status, ...keys], [true, ...keys.map(() => [])])

      return
    }

    if ((status === 'hotNews' || status === 'mainRejected') && !statuses[status]) {
      const keys = Object.keys(statuses).filter((status) => status !== 'hotNews' && status !== 'mainRejected')

      setLocationParams([status, ...keys], [true, ...keys.map(() => [])])

      return
    }

    if (statuses[status]) {
      setLocationParams(status, [])
    } else {
      setLocationParams(status, true)
    }
  }

  const onSelectAuthors = (authors) => {
    setLocationParams('authorsId', authors.map((a) => a._id))
  }

  const onPageChange = (page) => {
    setLocationParams('page', page)
  }

  const onDateChange = ([start, end]) => {
    setLocationParams(['dateFrom', 'dateTo'], [start.valueOf(), end ? end.valueOf() : false])
  }

  async function setAccessByLink(index) {
    try {
      const news = { ...posts[index] }

      news.accessLinkOnly = !news.accessLinkOnly
      news.hotNews = false
      news.mainRejected = true

      setPosts([...posts.slice(0, index), news, ...posts.slice(index + 1)])

      await POSTS.update(news)
    } catch (error) {
      console.error(error)
    }
  }

  async function addToMainNews(index) {
    try {
      const news = { ...posts[index] }

      news.mainRejected = !news.mainRejected
      news.accessLinkOnly = false

      setPosts([...posts.slice(0, index), news, ...posts.slice(index + 1)])

      await POSTS.update(news)
    } catch (error) {
      console.error(error)
    }
  }

  async function setHotNews(index) {
    try {
      const news = { ...posts[index] }

      news.hotNews = !news.hotNews
      news.accessLinkOnly = false

      setPosts([...posts.slice(0, index), news, ...posts.slice(index + 1)])

      await POSTS.update(news)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <LibraryBox>
      <LibraryFilters
        activeRubrics={activeRubrics}
        date={date}
        setDate={onDateChange}
        currentAuthorsIds={activeAuthors}
        setAuthors={onSelectAuthors}
        onSelectRubric={onSelectRubric}
        rubrics={rubrics}
        dateDisabled={!!queryString.parse(location.search)?.search}
        onSelectType={onSelectType}
        activeTypes={activeTypes}
        onSelectStatus={onSelectStatus}
        activeStatuses={statuses}
        initialStartDate={startDate}
        initialEndDate={endDate}
      />

      <div className="content">
        <div className="search-wrapper">
          <Icon type="zoom" onClick={onSearch} />
          <input
            placeholder="Поиск..."
            className="search-input"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSearch()
              }
            }}
          />
        </div>

        {loading ? <ArticlesListPlaceholder /> : (
          <ArticlesList
            onSelect={(index) => onPreviewOpen(index)}
            articles={posts}
            setAccessByLink={setAccessByLink}
            addToMainNews={addToMainNews}
            setHotNews={setHotNews}
          />
        )}

        {!postsCount && !loading && (
        <div className="empty">
          По заданным фильтрам ничего не найдено
        </div>
        )}

        {!!postsCount && !loading && (
        <div className="pagination-wrapper">
          <Pagination total={postsCount || 0} perPage={20} page={page} onPageChange={onPageChange} />
        </div>
        )}
      </div>

      {previewIndex !== null && !!posts.length && (
        <ArticlePreview
          id={posts[previewIndex]._id}
          date={posts[previewIndex].date}
          author={posts[previewIndex].author}
          type={posts[previewIndex].type}
          requiredBlocks={posts[previewIndex].requiredBlocks}
          optionalBlocks={posts[previewIndex].optionalBlocks}
          selectedTags={posts[previewIndex].tags}
          onClose={() => onPreviewOpen(null)}
          rubricName={articleMock.category.name}
          lettersCount={articleMock.lettersCount}
          format="DD MMMM YYYY"
          withEdit
        />
      )}
    </LibraryBox>
  )
}
