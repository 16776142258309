import styled from 'styled-components'

import { FONTS } from 'constants/fonts'
import rightQuoteImg from 'assets/images/quote-right.png'

export const ArticlePreviewBox = styled.div`
  position: fixed;
  top: 0;
  left: 108px;
  background: white;
  width: calc(100vw - 108px);
  height: 100vh;
  overflow: auto;
  z-index: 4;
  display: flex;
  justify-content: center;
  overflow-x: hidden;

  @media screen and (max-width: 1649px) {
    height: calc(100vh * 1.45);
    width: calc(100vw * 1.25 - 108px);
  }

  @media screen and (max-width: 1440px) {
    width: calc(100vw * 1.45 - 108px);
  }

  &.modal {
    overflow: visible;
    height: 90vh;
    width: 1030px;
    left: 534px;
    top: 50px;
    -webkit-backdrop-filter: blur(22.8px);
    backdrop-filter: blur(22.8px);
    box-shadow: 0 0 56px 0 rgba(0, 0, 0, 0.16);
    background-color: white;

    .preview-overlay {
      position: fixed;
      left: 508px;
      top: 0;
      height: 100%;
      width: calc(100vw - 508px);
      background: white;
      opacity: 0.6;
      backdrop-filter: blur(6.1px);
      background-color: #ffffff;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 300px;
      left: -19px;
      border-width: 30px;
      border-style: solid;
      border-color: transparent white transparent transparent;
      transform: translateX(-50%);
    }

    .modal-header {
      margin-top: 16px;
      margin-bottom: 16px;
      display: flex;
      width: 100%;
      justify-content: flex-end;

      .icon-cross {
        width: 30px;
        height: 30px;
        background: #897b6a;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .cls-2 {
          stroke: white;
        }
      }
    }

    .content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      padding: 0 15px 0 70px;
      z-index: 3;

      .category {
        margin-top: 0;
      }

      .scrollbar-content {
        margin-right: 45px;
      }
    }
  }

  .buttons {
    width: 352px;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: #e7e8ea;

    .close-preview {
      margin: 37px 29px 24px 29px;
      position: relative;
      font-family: Roboto;
      font-size: 24px;
      font-weight: 900;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.92;
      letter-spacing: -0.38px;
      text-align: left;
      color: black;
      text-transform: uppercase;
      cursor: pointer;

      .cross {
        position: absolute;
        left: -41px;
        top: -4px;
        background: #897b6a;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;

        .icon-cross {
          width: 24px;
          height: 24px;

          svg {
            width: 24px;
            height: 24px;
          }

          .cls-2 {
            stroke: white;
          }
        }
      }
    }

    .bottom-buttons {
      width: 352px;
      padding: 22px 30px;
      box-sizing: border-box;
      -webkit-backdrop-filter: blur(2.7px);
      backdrop-filter: blur(2.7px);
      box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
      background-color: #484b50;
    }

    .edit {
      margin-top: 20px;
      width: 292px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 26px;
      box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
      background-color: #7c8783;
      padding: 0 8px;
      display: flex;
      align-items: center;
      font-family: ${FONTS.roboto};
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: black;
      text-transform: uppercase;

      .icon-edit-wrapper {
        margin-right: 26px;
        width: 30px;
        height: 30px;
        border: solid 3px #454545;
        background-color: #ce914a;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .icon-edit {
          width: 17px;
          height: 17px;

          svg {
            width: 17px;
            height: 17px;
          }

          .cls-1 {
            fill: white;
          }
        }
      }
    }

    .create-new {
      margin-top: 20px;
      width: 292px;
      height: 34px;
      border-radius: 21px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #ce914a;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      text-transform: uppercase;
      color: black;
      display: flex;
      align-items: center;

      .icon-create-article {
        margin-left: 2px;
        margin-right: 54px;
        width: 30px;
        height: 30px;

        svg {
          height: 30px;
          width: 30px;

          g {
            fill: #aa7a42;
          }
        }
      }
    }

    .publish {
      width: 292px;
      height: 34px;
      border-radius: 21px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #ce914a;
      text-transform: uppercase;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: black;
      padding: 0 6px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &.disabled {
        box-shadow: none;
        pointer-events: none;

        .check {
          .icon-check {
            .cls-2 {
              fill: #454545;
            }
          }
        }
      }

      .check {
        margin-right: 26px;
        width: 30px;
        height: 30px;
        border: solid 3px #454545;
        background-color: #6c6c6c;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;

        .icon-check {
          width: 16px;
          height: 16px;

          svg {
            width: 16px;
            height: 16px;

            .cls-2 {
              fill: white;
            }
          }
        }
      }
    }

    .unpublish {
      margin-top: 20px;
      width: 292px;
      height: 32px;
      border-radius: 21px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
      background-color: #de4e4e;
      cursor: pointer;
      text-transform: uppercase;
      font-family: Roboto;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      display: flex;
      align-items: center;

      .eye-wrapper {
        margin-left: 2px;
        margin-right: 22px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #c13b3b;
        border-radius: 50%;
      }

      .icon-eye-crossed {
        border-radius: 50%;

        svg {
          border-radius: 50%;
        }

        .cls-1 {
          fill: #c13b3b;
        }
      }
    }
  }

  .link-to-publication {
    margin-left: 30px;
    margin-top: 26px;
    margin-bottom: 22px;
    width: 292px;
    height: 34px;
    border-radius: 21px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #d5d5d8;
    font-family: Roboto;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: center;
    color: black;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    .open {
      margin-left: 2px;
      margin-right: 45px;
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #484b50;
      border-radius: 50%;

      .icon-open {
        width: 24px;
        height: 24px;
        background: transparent;
        border-radius: 50%;

        svg {
          border-radius: 50%;
          width: 24px;
          height: 24px;
        }

        .cls-1 {
          fill:#484b50;
        }

        .cls-2 {
          fill: white;
        }

        .cls-3 {
          fill: white;
        }
      }
    }
  }

  .content {
    width: 900px;
    height: max-content;
    padding-bottom: 100px;
    word-break: break-word;

    .category {
      margin-top: 50px;
      font-family: ${FONTS.oswald};
      font-size: 14px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: 1.05px;
      text-align: left;
      color: #ff565c;
      text-transform: uppercase;
    }

    .title {
      margin-top: 9px;
      font-family: ${FONTS.oswald};
      font-size: 42px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.24;
      letter-spacing: 0.71px;
      text-align: left;
      color: black;
    }

    .lead {
      margin-top: 16px;
      font-family: ${FONTS.lato};
      font-size: 22px;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.45;
      letter-spacing: -0.11px;
      text-align: left;
      color: black;
    }

    .controls {
      margin-top: 40px;
      margin-bottom: 40px;
      display: block;
      width: 102px;
      height: 42px;
    }

    .tags {
      display: flex;

      .tag {
        margin-right: 16px;
        height: 32px;
        padding: 0 8px 0 12px;
        border-radius: 2px;
        border: 1px solid #d5d5d8;
        background-color: #fff;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        cursor: pointer;

        .tag-name {
          margin-right: 12px;
          font-family: ${FONTS.myriadPro};
          font-size: 16px;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.05;
          letter-spacing: 1.2px;
          text-align: left;
          color: #484b50;
          text-transform: uppercase;
        }

        .icon-check {
          svg {
            width: 20px;

            .cls-2 {
              fill: #d8d8da;
            }
          }
        }
      }
    }

    .news-info {
      margin-top: 20px;
      margin-bottom: 16px;
      height: 40px;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .controls {
        width: 102px;
        height: 42px;
      }

      .author-name {
        margin-left: 68px;
        opacity: 0.4;
        font-family: ${FONTS.lato};
        font-size: 14px;
        font-weight: 400;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }

      .divider {
        margin: 0 24px;
        height: 14px;
        width: 1px;
        background: #d5d5d8;
      }

      .date {
        opacity: 0.4;
        font-family: ${FONTS.lato};
        font-size: 14px;
        font-weight: 400;
        line-height: 1.86;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }

      .time {
        margin-left: auto;
        padding: 0 12px 0 7px;
        height: 40px;
        border-radius: 2px;
        background-color: #f8f3e5;
        display: flex;
        align-items: center;
        text-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
        font-weight: 500;
        line-height: 2.14;
        text-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        font-family: ${FONTS.lato};
        font-size: 14px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 2.14;
        letter-spacing: normal;
        text-align: left;
        color: black;

        .icon-timer {
          margin-right: 5px;
        }
      }
    }

    .image-full-width {
      margin-top: 28px;
      width: 100%;
      display: block;
    }

    .image-description {
      margin-top: 18px;
      opacity: 0.4;
      font-family: ${FONTS.lato};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }

    .text, .text-float {
      margin-top: 28px;
      font-family: ${FONTS.lato};
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: rgba(29, 31, 36, 0.7);

      .link {
        text-decoration: underline;
        color: inherit;
        font-style: inherit;
        font-family: inherit;
      }

      p {
        min-height: 18px;
      }
    }

    .text-float {
      min-height: 158px;

      &.double {
        min-height: 280px;
      }

      .float-block {
        margin-left: 20px;
        margin-bottom: 20px;
        float: right;
        width: 250px;
        background: #FFFFFF;
        border: 0.5px solid #D5D5D8;
        border-bottom: 3px solid #25A6B6;
        box-sizing: border-box;
        display: block;

        &.left {
          float: left;
          margin-left: 0;
          margin-right: 20px;
        }

        .float-block-image-wrapper {
          width: 100%;
          height: 152px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f8f3e5;
        }

        .float-block-image {
          width: 70px;
          height: 70px;
        }

        .float-block-title {
          width: 100%;
          height: 35px;
          padding: 0 25px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          background: #25A6B6;
          color: white;
          font-weight: bold;
          font-size: 15px;
          line-height: 18px;
          letter-spacing: 1.05px;
          text-transform: uppercase;
        }

        .float-block-content {
          padding: 25px 0;
          margin: 0 25px;

          &.second {
            border-top: 1px solid #C4C4C4;
          }

          .float-block-category {
            font-family: Oswald;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 1.05px;
            text-transform: uppercase;
            color: #FF565C;
          }

          .float-block-text {
            margin-top: 20px;
            font-family: Oswald;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            letter-spacing: 0.714px;
            color: black;
          }
        }
      }
  }

    .quote {
      margin-top: 30px;
      padding: 30px 40px 30px 40px;
      border-radius: 4px;
      background: #f8f3e5;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      font-style: normal;
      text-align: center;
      color: black;
      word-break: break-word;
      position: relative;

      &.with-image {
        margin-bottom: 60px;
        padding-bottom: 70px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -20px;
        top: -20px;
        width: 50px;
        height: 58px;
        background: url(${rightQuoteImg});
        transform: rotate(180deg);
        background-size: 100% 100%;
      }

      &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: -20px;
        right: -20px;
        width: 50px;
        height: 58px;
        background: url(${rightQuoteImg});
        background-size: 100% 100%;
      }

      .quote-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        font-style: normal;
        text-align: center;
        color: black;
      }

      .quote-author {
        margin-top: 20px;
        width: 100%;
        font-style: italic;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #707070;
      }

      .quote-image {
        width: 120px;
        height: 120px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .main-in-text {
      margin-top: 30px;
      padding: 30px 40px 30px 40px;
      border-radius: 4px;
      background: #f8f3e5;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      font-style: normal;
      text-align: center;
      color: black;
      word-break: break-word;
      position: relative;

      .main-in-text-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        font-style: normal;
        text-align: center;
        color: black;
      }

      .main-in-text-author {
        margin-top: 20px;
        width: 100%;
        font-style: italic;
        font-weight: normal;
        font-size: 17px;
        line-height: 20px;
        text-align: center;
        color: #707070;
      }
    }
    
    .previously-by-theme {
      margin-top: 30px;
      background: #FFFFFF;
      border: 0.5px solid #D5D5D8;
      box-sizing: border-box;
      border-left: 3px solid #25A6B6;
      display: flex;
      align-items: center;
      padding: 23px 30px;

      .previously-by-theme-text {
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: black;
      }

      .previously-by-theme-title {
        margin-left: 30px;
        width: 450px;
        font-family: Oswald;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 28px;
        letter-spacing: 0.714px;
        color: #25A6B6;
      }
    }

    .h2 {
      margin-top: 40px;
      font-family: ${FONTS.myriadPro};
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 0.84;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }

    .embedded-container {
      margin-top: 28px;
      width: 100%;

      display: flex;
      justify-content: center;

      iframe {
        max-width: 100%;
      }
    }

    .related-news {
      margin-top: 50px;
      width: 100%;
      padding: 50px 0;
      position: relative;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;

      .background {
        position: absolute;
        background: #f9fafc;
        top: 10px;
        left: -100vw;
        width: 200vw;
        height: 100%;
        z-index: -1;
      }

      .related-news-title {
        margin-bottom: 8px;
        width: 100%;
        text-align: center;
        font-family: ${FONTS.oswald};
        font-size: 32px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.63;
        letter-spacing: 0.54px;
        color: black;
      }

      .news {
        margin-top: 20px;
        width: 700px;
        display: flex;
        background: #fff;
        padding: 10px 20px 10px 10px;

        .image {
          width: 100px;
          height: 100px;
          min-width: 100px;
          min-height: 100px;
          -o-object-fit: cover;
          object-fit: cover;
        }

        .info {
          margin-left: 16px;

          .news-title {
            margin-top: 4px;
            font-family: ${FONTS.oswald};
            font-size: 20px;
            font-weight: 600;
            line-height: 1.3;
            letter-spacing: -0.1px;
            text-align: left;
            color: black;
          }

          .news-text {
            margin-top: 8px;
            opacity: 0.4;
            text-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
            font-family: ${FONTS.lato};
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: normal;
            text-align: left;
            color: black;
          }
        }
      }
    }

    .event {
      margin-top: 50px;
      display: flex;

      .time {я
        margin-top: 4px;
        margin-right: 63px;
        width: 39px;
        font-family: ${FONTS.oswald};
        font-size: 18px;
        font-weight: 500;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #d5d5d8;
      }

      .text {
        margin-top: 0;
        opacity: 0.7;
        font-family: ${FONTS.lato};
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: black;
      }
    }

    .not-full-news {
      margin-top: 20px;
      font-family: Lato;
      font-style: italic;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: black;
      mix-blend-mode: normal;
    }
  }
`
