import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { Scrollbars } from 'react-custom-scrollbars'
import moment from 'moment'

import { Icon } from 'ui/atoms'
import articleControlsImg from 'assets/images/article-controls.png'
import imgPreviewImg from 'assets/images/dropzone-preview.png'
import { convertLettersCountToMinutes } from 'helpers'
import { ArticlePreviewBox } from './articlePreview.style'

const ConditinalyRenderScrollbar = ({ withScrollbars = false, children }) => {
  if (withScrollbars) {
    return (
      <Scrollbars autoHeight autoHeightMax="85vh" renderTrackHorizontal={() => <div />}>
        <div className="scrollbar-content">{children}</div>
      </Scrollbars>
    )
  }

  return <>{children}</>
}

const renderBlock = (block, index) => {
  if (block.type === 'text') {
    if (block.params.withInserts) {
      return (
        <div className={classNames({ 'text-float': true, double: block.params.insertsCount === 2 })}>
          <div className={classNames({ 'float-block': true, left: block.params.insertsPosition === 'left' })}>
            <div className="float-block-title">
              Сейчас читают
            </div>

            {block.params.withPhoto
              && (
              <div className="float-block-image-wrapper">
                <img className="float-block-image" src={imgPreviewImg} alt="" />
              </div>
              )}

            <div className="float-block-content">
              <div className="float-block-category">
                Тэг
              </div>
              <div className="float-block-text">
                Заголовок
              </div>
            </div>

            {block.params.insertsCount === 2
          && (
            <div className="float-block-content second">
              <div className="float-block-category">
                Тэг
              </div>
              <div className="float-block-text">
                Заголовок
              </div>
            </div>
          )}
          </div>

          <div dangerouslySetInnerHTML={{ __html: block.html }} key={index} />
        </div>
      )
    }

    return <div className="text" dangerouslySetInnerHTML={{ __html: block.html }} key={index} />
  }

  if (block.type === 'quote' && block.params) {
    return (
      <div className={classNames({ quote: true, 'with-image': !!block.params.photoUrl })}>
        <div className="quote-text" dangerouslySetInnerHTML={{ __html: block.html }} key={index} />
        {!!block.params.source && <div className="quote-author">{block.params.source}</div>}
        {!!block.params.photoUrl && <img className="quote-image" src={block.params.photoUrl} alt="" />}
      </div>
    )
  }

  if (block.type === 'main-in-text' && block.params) {
    return (
      <div className="main-in-text">
        <div className="main-in-text-text" dangerouslySetInnerHTML={{ __html: block.html }} key={index} />
        {!!block.params.source && <div className="main-in-text-author">{block.params.source}</div>}
      </div>
    )
  }

  if (block.type === 'previously-by-theme' && block?.params?.postData) {
    return (
      <div className="previously-by-theme">
        <div className="previously-by-theme-text">Ранее по теме:</div>
        <div className="previously-by-theme-title">{block.params.postData.title}</div>
      </div>
    )
  }

  if (block.type === 'h2') {
    return <div className="h2" dangerouslySetInnerHTML={{ __html: block.html }} key={index} />
  }

  if (block.type === 'embedded') {
    return <div className="embedded-container" dangerouslySetInnerHTML={{ __html: block.html }} />
  }

  if (block.type === 'image' && block.params) {
    return (
      <React.Fragment key={index}>
        <img className="image-full-width" src={block.params.photoUrl || block.params?.photoUrls?.origin} alt="" />
        <div className="image-description">{block.params.source}</div>
      </React.Fragment>
    )
  }

  if (block.type === 'links') {
    return (
      <div className="related-news" key={index}>
        <div className="related-news-title">Новости по теме</div>
        {!!block.params.links
          && block.params.links.map((link) => (
            <div className="news" key={link._id}>
              <div className="background" />
              <img className="image" src={link.photo.url} alt="" />
              <div className="info">
                <div className="news-title">{link.title}</div>
                <div className="news-text">{link.lead}</div>
              </div>
            </div>
          ))}
      </div>
    )
  }

  if (block.type === 'live') {
    return (
      <div className="event" key={index}>
        <div className="time">{block.params.time}</div>
        <div className="text" dangerouslySetInnerHTML={{ __html: block.html }} />
      </div>
    )
  }

  return null
}

export const ArticlePreview = ({
  onClose,
  requiredBlocks,
  optionalBlocks,
  type = 'article',
  rubricName,
  lettersCount,
  selectedTags,
  id,
  withEdit = false,
  withPublish = false,
  isModal = false,
  author,
  publish,
  published,
  publishDisabled,
  validationEnabled,
  url,
  date,
}) => {
  useEffect(() => {
    const body = document.querySelector('body')

    body.style.overflow = 'hidden'

    return () => {
      body.style.overflow = 'auto'
    }
  }, [])

  return ReactDOM.createPortal(
    <ArticlePreviewBox className={classNames({ modal: isModal })}>
      {!isModal && (
        <div className="buttons">
          <div className="close-preview" onClick={onClose}>
            <div className="cross">
              <Icon type="cross" />
            </div>
            Закрыть превью
          </div>

          {published && (
          <a className="link-to-publication" href={url} target="_blank" rel="noopener noreferrer">
            <div className="open">
              <Icon type="open" />
            </div>
            Открыть на сайте
          </a>
          )}

          {((withPublish && !publishDisabled && !published) || (withPublish && published) || withEdit) && (
          <div className="bottom-buttons">
            {withPublish && !publishDisabled && !published && (
            <div className="publish" onClick={publish}>
              <div className="check">
                <Icon type="check" />
              </div>
              Подтвердить публикацию
            </div>
            )}

            {withPublish && published && (
              <Link to="/redactor">
                <div className="create-new">
                  <div className="edit-wrapper">
                    <Icon type="create-article" />
                  </div>
                  Создать новый
                </div>
              </Link>
            )}

            {withEdit && (
              <Link to={`/redactor/${type}/${id}`}>
                <div className="create-new" onClick={publish}>
                  <div className="edit-wrapper">
                    <Icon type="create-article" />
                  </div>
                  Редактировать
                </div>
              </Link>
            )}

            {withPublish && published && (
            <div className="unpublish" onClick={publish}>
              <div className="eye-wrapper">
                <Icon type="eye-crossed" />
              </div>
              Снять с публикации
            </div>
            )}
          </div>
          )}

        </div>
      )}

      <div className="content">
        {isModal && (
        <div className="modal-header">
          <Icon type="cross" onClick={onClose} />
        </div>
        )}

        <ConditinalyRenderScrollbar withScrollbars={isModal}>
          <div className="category">{rubricName}</div>

          {requiredBlocks.map((block, index) => {
            if (block.type === 'title') {
              return <div className="title" dangerouslySetInnerHTML={{ __html: block.html }} key={index} />
            }

            if (block.type === 'lead') {
              return (
                <React.Fragment key={index}>
                  <div className="lead" dangerouslySetInnerHTML={{ __html: block.html }} />
                </React.Fragment>
              )
            }

            if (block.type === 'main-photo') {
              return (
                <React.Fragment key={index}>
                  {(type === 'article' || type === 'news') && (
                  <div className="news-info">
                    <img src={articleControlsImg} alt="" className="controls" />
                    <div className="author-name">{author}</div>
                    <div className="divider" />
                    <div className="date">{moment(date).format('DD MMMM, YYYY HH:mm')}</div>
                    <div className="time">
                      <Icon type="timer" />
                      <div>
                        {convertLettersCountToMinutes(lettersCount)}
                        {' '}
                        мин
                      </div>
                    </div>
                  </div>
                  )}
                  <img className="image-full-width" src={block.params.photoUrl || block.params?.photoUrls?.origin} alt="" />
                  <div className="image-description">{block.params.source}</div>
                </React.Fragment>
              )
            }

            if (block.type === 'live') {
              return (
                <div className="event" key={index}>
                  <div className="time">{block.params.time}</div>
                  <div className="text" dangerouslySetInnerHTML={{ __html: block.html }} />
                </div>
              )
            }

            return renderBlock(block)
          })}

          {!!optionalBlocks.length
              && optionalBlocks
                .slice(0, optionalBlocks[optionalBlocks.length - 1].type === 'links' ? -1 : optionalBlocks.length)
                .map((block, index) => renderBlock(block, index))}

          <img src={articleControlsImg} alt="" className="controls" />

          <div className="tags">
            {selectedTags.map((tag) => (
              <div className="tag" key={tag.name}>
                <div className="tag-name">{tag.name}</div>
                <Icon type="check" />
              </div>
            ))}
          </div>

          {!!optionalBlocks.length
              && optionalBlocks[optionalBlocks.length - 1].type === 'links'
              && optionalBlocks.slice(-1).map((block, index) => renderBlock(block, index))}

          {!validationEnabled
            && (
            <div className="not-full-news">
              Новость дополняется...
            </div>
            )}

        </ConditinalyRenderScrollbar>
      </div>
    </ArticlePreviewBox>,
    document.querySelector('body'),
  )
}
