import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Editor, EditorState, RichUtils, CompositeDecorator,
} from 'draft-js'
import 'draft-js/dist/Draft.css'

import { FONTS } from 'constants/fonts'
import InputMask from 'react-input-mask'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'
import { StyledLink } from './editor.style'
import { convertToHtml, convertFromHtml } from './convert'

const LinkEditorBox = styled.div`
  display: flex;

  .time-input {
    margin-right: 32px;
    width: 66px;
    height: 34px;
    border-radius: 4px;
    border: solid 2px #d56c45;
    background-color: #ffffff;
    outline: none;
    box-sizing: border-box;
    opacity: 0.4;
    font-family: ${FONTS.oswald};
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: black;
    padding: 0 8px;

    &::placeholder {
      margin-left: -4px;
      font-family: ${FONTS.oswald};
      font-size: 18px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: black;
      letter-spacing: -1px;
    }
  }

  .time {
    margin-top: 2px;
    margin-right: 32px;
    width: 39px;
    height: 26px;
    opacity: 0.4;
    font-family: ${FONTS.oswald};
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.44;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }
`

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity()

    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

const Link = ({ contentState, entityKey, children }) => {
  const { url } = contentState.getEntity(entityKey).getData()

  return (
    <StyledLink href={url} title={url} className="ed-link">
      {children}
    </StyledLink>
  )
}

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link,
  },
])

const LiveTextEditor = ({
  withToolbar = false,
  readOnly = false,
  type,
  className = '',
  placeholder,
  active,
  blockTypeIcon,
  editorRef,
  toolbarOptions,
  initialState,
  deactivate,
  maxLineCount = Infinity,
  setError,
  onSave,
}) => {
  const [time, setTime] = useState(initialState.time)

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(convertFromHtml(initialState.html), decorator),
  )

  const onEditorChange = (state) => {
    const blockArray = state.getCurrentContent().getBlocksAsArray()

    if (blockArray && blockArray.length > maxLineCount) {
      setError(true)
    } else {
      setError(false)
    }

    setEditorState(state)
  }

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const setLink = () => {
    const urlValue = prompt('Введите ссылку', '')

    const contentState = editorState.getCurrentContent()

    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', { url: urlValue })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()

    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })

    setEditorState(RichUtils.toggleLink(newEditorState, newEditorState.getSelection(), entityKey))
  }

  const _onSave = () => {
    deactivate()

    onSave({
      html: convertToHtml(editorState.getCurrentContent()),
      params: { lettersCount: editorState.getCurrentContent().getPlainText('').length, time },
    })
  }

  return (
    <>
      <LinkEditorBox>
        {active ? (
          <InputMask
            className="time-input"
            onChange={(e) => setTime(e.target.value)}
            value={time}
            placeholder="ЧЧ:ММ"
            mask="99:99"
            maskChar={null}
          />
        ) : (
          <div className="time">{time || 'ЧЧ:ММ'}</div>
        )}

        <Editor
          editorState={editorState}
          onChange={onEditorChange}
          readOnly={!active}
          placeholder={!active ? placeholder : null}
          ref={editorRef}
        />

        {(!!blockTypeIcon || withToolbar) && active && (
          <EditorToolbar
            editorState={editorState}
            onToggle={toggleInlineStyle}
            blockTypeIcon={blockTypeIcon}
            availableOptions={toolbarOptions}
            setLink={setLink}
          />
        )}
      </LinkEditorBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const enhanced = withEditorContainer(LiveTextEditor)

export { enhanced as LiveTextEditor }
