import styled from 'styled-components/macro'

import { FONTS } from 'constants/fonts'

export const EditorBox = styled.div`
  width: 900px;
  /* height: 114px; */
  border-radius: 4px;
  border: solid 1px #c1c1c1;
  padding: 0 8px;
  font-family: ${FONTS.oswald};
  font-size: 42px;
  font-weight: 500;
  line-height: 1.24;
  letter-spacing: 0.71px;
  box-sizing: border-box;
  position: relative;
  background: white;
  cursor: pointer;
  /* pointer-events: none; */

  &:hover {
    border-color: black;
  }

  .start-changing {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    /* right: -48px;
    top: -1px;
    width: 48px;
    height: 100%;
    background-color: #f8f8f8;
    border: solid 1px #897b6a;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    cursor: pointer;
    pointer-events: auto;

    &:hover {
      background-color: #f9f3e5;
      border-color: #ce914a;
    } */
  }

  .done {
    min-width: 52px;
    height: 36px;
    position: absolute;
    bottom: -1px;
    right: -1px;
    z-index: 3;
    padding: 0 6px;
    background: #c3b29b;
    letter-spacing: normal;
    text-align: left;
    color: black;
    border-top-left-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom-right-radius: 8px;
    box-sizing: border-box;

    .letters-count {
      margin: 0 19px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      letter-spacing: 0.7px;
      color: #000000;
    }

    .icon-check {
      cursor: pointer;
      background: white;
      width: 40px;
      height: 26px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
    }
  }

  .delete {
    position: absolute;
    top: -37px;
    right: -1px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #d5d4d9;
    border-radius: 2px;
    border-bottom-right-radius: 0;

    .cls-2 {
      stroke: #ff565b;
    }
  }

  &.active {
    border: dashed 1px #897b6a;
    background-color: #f5f5f5;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
    pointer-events: auto;
    cursor: initial;

    &.with-delete-icon {
      border-top-right-radius: 0;
    }

    &.with-options {
      border-top-left-radius: 0;
    }
  }

  &.error {
    border-color: red;
  }

  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    width: 100%;
  }

  .public-DraftEditorPlaceholder-root {
    color: black;
  }

  &.title {
    min-height: 114px;
    font-family: ${FONTS.oswald};
    font-size: 42px;
    font-weight: 500;
    line-height: 1.24;
    letter-spacing: 0.71px;
    color: black;

    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      min-height: 114px;
    }
  }

  &.lead {
    min-height: 60px;
    font-family: ${FONTS.lato};
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.45;
    letter-spacing: -0.11px;
    text-align: left;
    color: black;

    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      min-height: 60px;
    }
  }

  &.text {
    min-height: 105px;
    font-family: ${FONTS.lato};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: black;

    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      min-height: 100px;
    }
  }

  &.live {
    min-height: 105px;
    font-family: ${FONTS.lato};
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: black;

    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      min-height: 100px;
    }
  }

  &.main-photo {
    padding: 0;
    min-height: 485px;

    &.active {
      border: none;

      .done {
        bottom: 0;
        right: 0;
      }

      .delete {
        top: -36px;
        right: 0;
      }
    }
  }

  &.h2 {
    font-family: ${FONTS.myriadPro};
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 0.84;
    letter-spacing: normal;
    text-align: left;
    color: black;
  }

  &.quote {
    min-height: 220px;
    font-family: ${FONTS.lato};
    font-size: 22px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.55;
    letter-spacing: -0.11px;
    text-align: left;
    color: black;
    position: relative;
    background: #f8f8f8;

    &:not(.active) {
      border-left: none;
      border-width: 2px;
      border-radius: 0;
      padding: 35px;
      box-sizing: border-box;
    }

    .DraftEditor-root,
    .DraftEditor-editorContainer,
    .public-DraftEditor-content {
      min-height: 170px;
    }

    .start-changing {
      top: -2px;
      height: calc(100% + 2px);
    }
  }

  &.previously-by-theme {
    
  }

  &.main-in-text {
    min-height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: black;
    position: relative;
    text-align: center;
    padding: 15px;
  }

  &.links {
    min-height: 200px;
  }
`

export const StyledLink = styled.a`
  text-decoration: underline;
  color: inherit;
  font-family: inherit;
`
