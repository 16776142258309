import React, { useCallback, useState } from 'react'
import styled from 'styled-components/macro'
import { Editor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'
import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { NotSelected } from 'ui/atoms'
import { Icon } from 'ui/atoms/Icon/index'
import { POSTS } from 'api/posts'
import { useDropzone } from 'react-dropzone'
import dropzonePreviewImg from 'assets/images/dropzone-preview.png'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'
import { convertToHtml, convertFromHtml } from './convert'

const QuoteEditorBox = styled.div`
  .quote-source {
    margin-top: 12px;
    margin-bottom: 12px;
    font-family: ${FONTS.lato};
    font-size: 18px;
  }

  .source-input-wrapper {
    display: flex;
    align-items: center;
  }

  .quote-source-input {
    margin-bottom: 8px;
    box-sizing: border-box;
    margin-top: 8px;
    display: block;
    width: 300px;
    height: 36px;
    outline: none;
    border-radius: 4px;
    border: solid 2px #d5d5d8;
    background-color: white;
    font-family: ${FONTS.lato};
    padding-left: 8px;
    font-style: italic;
    font-stretch: normal;
    font-size: 14px;

    &::placeholder {
      opacity: 0.2;
      font-family: ${FONTS.lato};
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }

  .dropzone {
    width: 820px;
    height: 100px;
    margin-left: -8px;
    margin-bottom: -16px;
    background-color: #ebe2d5;
    box-sizing: border-box;
    outline: none;
    display: flex;
    align-items: center;
    border-radius: 0 8px;
    border: 1px dashed #897B6A;
    border-left: none;
    border-bottom: none;
    padding: 10px 18px;

    &.hidden {
      display: none;
    }

    &.default {
      margin-left: -33px;
      margin-bottom: -36px;
      border-bottom-left-radius: 0;
    }

    input {
      display: none;
    }

    .title {
      font-family: ${FONTS.lato};
      font-size: 22px;
      line-height: 26px;
      letter-spacing: -0.11px;
      color: black;
      font-style: normal;
      mix-blend-mode: normal;
      opacity: 0.45;
    }

    .dropzone-preview-wrapper {
      margin-right: 25px;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      border: 1px dashed #897B6A;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F5F5F5;
    }

    .dropzone-preview {
      width: 36px;
      height: 36px;

      &.uploaded {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .upload {
      margin-top: 14px;
      width: 221px;
      height: 36px;
      border-radius: 4px;
      background-color: #d5d5d8;
      font-family: ${FONTS.roboto};
      font-size: 18px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: normal;
      text-align: left;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`

const QuoteEditor = ({
  placeholder, active, editorRef, onSave, deactivate, initialState,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromHtml(initialState.html)))
  const [source, setSource] = useState(initialState?.params?.source || '')
  const [withSource, toggleSource] = useState(!!initialState?.params?.source)
  const [withPhoto, togglePhoto] = useState(!!initialState?.params?.photoUrl)
  const [photoUrl, setPhotoUrl] = useState(initialState?.params?.photoUrl || '')

  const onDropPhoto = useCallback(async (acceptedFiles) => {
    try {
      const response = await POSTS.uploadImage({ image: { blob: acceptedFiles[0], fileName: 'upload.jpeg' } })

      setPhotoUrl(response.data.url)
    } catch (error) {
      console.error(error)
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop: onDropPhoto, accept: 'image/jpeg, image/png, image/jpg' })

  const onEditorChange = (state) => {
    setEditorState(state)
  }

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const handleSourceToggleClick = () => {
    toggleSource((state) => !state)
  }

  const handlePhotoToggleClick = () => {
    togglePhoto((state) => !state)
  }

  const handleRemovePhoto = () => {
    setPhotoUrl('')
  }

  const _onSave = () => {
    const html = convertToHtml(editorState.getCurrentContent())

    onSave({ html, params: { source: withSource ? source : '', photoUrl: withPhoto ? photoUrl : '' } })

    deactivate()
  }

  const editorToolbarCustomContent = (
    <>
      <div
        className={classNames('option', { active: withSource })}
        onClick={handleSourceToggleClick}
      >
        <Icon type="author" />
      </div>

      <div
        className={classNames('option', { active: withPhoto })}
        onClick={handlePhotoToggleClick}
      >
        <Icon type="add-image" />
      </div>
    </>
  )

  return (
    <>
      <QuoteEditorBox>
        <Editor
          editorState={editorState}
          onChange={onEditorChange}
          readOnly={!active}
          placeholder={!active ? placeholder : null}
          ref={editorRef}
        />

        {active && (
          <EditorToolbar
            editorState={editorState}
            onToggle={toggleInlineStyle}
            availableOptions={[]}
            blockTypeIcon="quote"
            customContent={editorToolbarCustomContent}
          />
        )}

        {active && withSource && (
          <div className="source-input-wrapper">
            <input
              className="quote-source-input"
              placeholder="Источник"
              onChange={(e) => setSource(e.target.value)}
              value={source}
            />
            {!source && <NotSelected text="не указан" />}
          </div>
        )}

        {!active && withSource && (
          <div className="quote-source">
            {source ? `Источник: ${source}` : 'Источник'}
          </div>
        )}

        {withPhoto
        && (
        <div className={classNames('dropzone', { default: !active })} {...(active ? getRootProps() : {})}>
          <input {...getInputProps()} />

          <div className="dropzone-preview-wrapper">
            <img src={photoUrl || dropzonePreviewImg} alt="" className={classNames({ 'dropzone-preview': true, uploaded: !!photoUrl })} />
          </div>

          <div>
            <div className="title">Перетащите фото из панели поиска или загрузите с компьютера</div>
            {photoUrl ? <div className="upload" onClick={handleRemovePhoto}>Удалить</div> : <div className="upload">Загрузить с компьютера</div>}
          </div>

        </div>
        )}
      </QuoteEditorBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const enhanced = withEditorContainer(QuoteEditor, 'quote')

export { enhanced as QuoteEditor }
