import React from 'react'
import styled from 'styled-components/macro'
// import classNames from 'classnames'

import { FONTS } from 'constants/fonts'
import { Icon } from 'ui/atoms'

const AddEditorBlockBox = styled.div`
  margin: 40px 0;
  width: 100%;
  height: 36px;
  position: relative;
  overflow: hidden;
  display: flex;
  opacity: 0.5;

  &::after {
    content: '';
    display: block;
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(to right, #1d1f24 50%, rgba(255, 255, 255, 0) 0%);
    background-position: left;
    background-size: 10px 1px;
    background-repeat: repeat-x;
    top: 0;
    left: 60px;
  }

  &:hover {
    opacity: 1;

    .options {
      display: flex;
    }
  }

  .add-block-title {
    height: 100%;
    padding: 0 10px;
    z-index: 3;
    background: white;
    display: flex;
    font-family: ${FONTS.roboto};
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: -0.22px;
    text-align: center;
    color: black;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .plus {
      margin-right: 4px;
      font-size: 18px;
    }
  }

  .options {
    margin-left: 200px;
    width: 240px;
    height: 36px;
    padding: 0 8px;
    border-radius: 4px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.25);
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
    display: none;

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 20px;
      left: -20px;
      top: 0;
      background: white;
      z-index: 2;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 20px;
      right: -20px;
      top: 0;
      background: white;
      z-index: 2;
    }

    .option {
      width: 26px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 2px;

      &:hover {
        background-color: #616366;
      }

      &:active {
        background-color: #d5d5d8;

        .icon-h2 {
          .cls-2 {
            fill: black;
          }
        }

        .icon-text,
        .icon-quote {
          .cls-1 {
            fill: black;
          }
        }

        .icon-shuffle {
          .cls-1 {
            fill: black;
            stroke: black;
          }
        }
      }
    }
  }
`

export const AddEditorBlock = ({ blocks, onAdd }) => (
  <AddEditorBlockBox>
    <div className="add-block-title">
      <div className="plus">+</div>
      Добавить блок
    </div>

    <div className="options">
      {blocks.map((block) => (
        <div className="option" onClick={() => onAdd(block)} key={block.type}>
          <Icon type={block.iconType} />
        </div>
      ))}
    </div>
  </AddEditorBlockBox>
)
