import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { compose } from 'recompose'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { convertLettersCountToMinutes } from 'helpers'

import { withPageLayout } from 'ui/templates'
import {
  TextEditor, MainPhotoEditor, AddEditorBlock, QuoteEditor, LinksEditor, LiveTextEditor, EmbeddedEditor,
} from 'features/Editor'
import { RightPanel } from 'features/RightPanel'
import { ArticlePreview } from 'features/Preview'
import { POSTS } from 'api/posts'
import { RequiredFieldsNotification } from 'features/Editor/RequiredFieldsNotification'
import { MainInTextEditor } from 'features/Editor/MainInTextEditor'
import { PreviouslyByThemeEditor } from 'features/Editor/PreviouslyByThemeEditor'
import { REDACTOR_CONFIG } from './config'
import { RedactorBox, BlocksBox } from './redactor.style'

const Redactor = ({ type, match, initialData }) => {
  const isFirstRender = useRef(true)

  const { id } = match.params

  const authors = useSelector((state) => state.news.authors)

  const [validationEnabled, toggleValidation] = useState(true)
  const [changed, setChanged] = useState(false)
  const [requiredNotificationActive, setRequiredNotificationActive] = useState(false)
  const [previewShowed, togglePreview] = useState(false)
  const [lettersCount, setLettersCount] = useState(0)
  const [currentRubric, setCurrentRubric] = useState(initialData.rubric || {})
  const [hot, setHot] = useState(initialData.hotNews)
  const [inGrid, setInGrid] = useState(false)
  const [accessLinkOnly, setAccessLinkOnly] = useState(initialData.accessLinkOnly)
  const [selectedTags, setSelectedTags] = useState(initialData.tags || [])
  const [date, setDate] = useState(initialData.date)
  const [nowChanging, setChangingBlocks] = useState([])
  const [currentAuthors, setAuthors] = useState([{ authorId: initialData.authorId, author: initialData.author }, ...initialData.subAuthors])
  const [published, setPublishingStatus] = useState(initialData.published)
  const [description, setDescription] = useState(initialData.description)
  const [pageTitle, setTitle] = useState(initialData.pageTitle)
  const [url, setUrl] = useState(initialData.url)

  const [requiredBlocks, setRequiredBlocks] = useState(
    initialData.requiredBlocks.map((block) => {
      const initialState = REDACTOR_CONFIG[type].requiredBlocks.find((b) => b.type === block.type)

      return {
        ...initialState,
        ...block,
      }
    }),
  )

  const [optionalBlocks, setOptionalBlocks] = useState(
    initialData.optionalBlocks.map((block) => {
      const initialState = REDACTOR_CONFIG[type].optionalBlocks.find((b) => b.type === block.type)

      return {
        ...initialState,
        ...block,
        keyForMap: Math.random(),
      }
    }),
  )

  const previewDisabled = requiredBlocks.some((block) => !block.html || Object.values(block.params).some((el) => el !== false && !el))

  const publishDisabled = previewDisabled || !currentRubric._id || selectedTags.length < 3 || !description

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href)

    const eventListener = () => {
      const leavePage = window.confirm('При возврате на предыдущую страницу все несохраненные изменения будут утеряны!')

      if (leavePage) {
        window.history.back()
      } else {
        window.history.pushState(null, document.title, window.location.href)
      }
    }

    window.addEventListener('popstate', eventListener)

    return () => window.removeEventListener('popstate', eventListener)
  }, [])

  async function updateMaterial() {
    try {
      const data = {
        requiredBlocks,
        optionalBlocks,
        _id: id,
        hotNews: hot,
        rubric: currentRubric._id,
        date,
        description,
        pageTitle,
        validationEnabled,
        authorId: currentAuthors[0].authorId,
        author: currentAuthors[0].author,
        accessLinkOnly,
        subAuthors: currentAuthors.slice(1),
        lettersCount: convertLettersCountToMinutes(lettersCount),
        tags: selectedTags.map((tag) => {
          if (tag.new) {
            return { name: tag.name }
          }

          return { name: tag.name, _id: tag._id }
        }),
      }

      await POSTS.update(data)
    } catch (error) {
      console.error(error)
    }
  }

  async function regenerate() {
    try {
      await POSTS.regenerate(id)

      setChanged(false)
    } catch (error) {
      console.error(error)
    }
  }

  async function publish() {
    if ((publishDisabled && validationEnabled) || !currentRubric.name) {
      setRequiredNotificationActive(true)

      return
    }

    try {
      const response = await POSTS.publish(id)

      setChanged(false)
      setUrl(response.data.url)
      setPublishingStatus(response.data.published)
    } catch (error) {
      console.error(error)
    }
  }

  function calculateLettersCount() {
    const lettersCountOnRequiredBlocks = requiredBlocks.reduce(
      (r, block) => r + ((block.params && block.params.lettersCount) || 0),
      0,
    )

    const lettersCountOnOptionalBlocks = optionalBlocks.reduce(
      (r, block) => r + ((block.params && block.params.lettersCount) || 0),
      0,
    )

    setLettersCount(lettersCountOnRequiredBlocks + lettersCountOnOptionalBlocks)
  }

  useEffect(calculateLettersCount, [requiredBlocks, optionalBlocks])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false

      return
    }

    setChanged(true)
    updateMaterial()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredBlocks, optionalBlocks, hot, inGrid, selectedTags, date, currentRubric, currentAuthors, selectedTags, description, accessLinkOnly, pageTitle, validationEnabled, lettersCount])

  const onDeleteBlock = (index) => {
    setOptionalBlocks([...optionalBlocks.slice(0, index), ...optionalBlocks.slice(index + 1)])
  }

  const onAuthorSelect = (author, index) => {
    setAuthors([...currentAuthors.slice(0, index), { ...currentAuthors[index], authorId: author }, ...currentAuthors.slice(index + 1)])
  }

  const onAddCoAuthor = () => {
    setAuthors([...currentAuthors, { authorId: null, author: '' }])
  }

  const removeCoAuthor = (index) => {
    setAuthors([...currentAuthors.slice(0, index), ...currentAuthors.slice(index + 1)])
  }

  const onPseudonymSelect = (author, index) => {
    setAuthors([...currentAuthors.slice(0, index), { ...currentAuthors[index], author }, ...currentAuthors.slice(index + 1)])
  }

  const onDateChange = (date) => {
    setDate(date.valueOf())
  }

  const onAddOptionalBlock = (index, block) => {
    if (block.type === 'links' && optionalBlocks.find((b) => b.type === 'links')) {
      window.scrollTo(0, 10000)

      return
    }

    if (block.type === 'links') {
      setOptionalBlocks([
        ...optionalBlocks,
        {
          ...block,
          type: block.type,
          html: '',
          params: {},
          keyForMap: Math.random(),
          opened: true,
        },
      ])

      setTimeout(() => window.scrollTo(0, 10000), 300)

      return
    }

    setOptionalBlocks([
      ...optionalBlocks.slice(0, index + 1),
      {
        ...block,
        type: block.type,
        html: '',
        params: {},
        keyForMap: Math.random(),
        opened: true,
      },
      ...optionalBlocks.slice(index + 1),
    ])

    window.scrollTo({ top: window.scrollY + 500 })
  }

  const onSaveRequiredBlock = (index) => ({ html, params = null }) => {
    if (index === 0) {
      setTitle(params.text)
    }

    setRequiredBlocks([
      ...requiredBlocks.slice(0, index),
      { ...requiredBlocks[index], html, params },
      ...requiredBlocks.slice(index + 1),
    ])
  }

  const onSaveOptionalBlock = (index) => ({ html, params = null }) => {
    setOptionalBlocks([
      ...optionalBlocks.slice(0, index),
      {
        ...optionalBlocks[index], html, params, opened: false,
      },
      ...optionalBlocks.slice(index + 1),
    ])
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const souceIndex = result.source.index
    const destinationIndex = result.destination.index

    const reorderedOptionalBlocks = [...optionalBlocks]

    const [removed] = reorderedOptionalBlocks.splice(souceIndex, 1)
    reorderedOptionalBlocks.splice(destinationIndex, 0, removed)

    setOptionalBlocks(reorderedOptionalBlocks)
  }

  const onChangeStart = (key) => {
    setChangingBlocks([...nowChanging, key])
  }

  const onChangeEnd = (key) => {
    setChangingBlocks(nowChanging.filter((i) => i !== key))
  }

  return (
    <RedactorBox>
      <BlocksBox>
        <div className={classNames('left-prompt-wrapper', { hidden: !validationEnabled })}>
          <div className="prompt">обязательные блоки</div>
        </div>

        <div className="editor-main-blocks">
          {requiredBlocks.map((block, index) => {
            if (block.type === 'main-photo') {
              return (
                <MainPhotoEditor
                  type="main-photo"
                  className={block.className}
                  key={index}
                  onSave={onSaveRequiredBlock(index)}
                  initialState={requiredBlocks[index].params}
                />
              )
            }

            if (block.type === 'live') {
              return (
                <LiveTextEditor
                  key={index}
                  type={block.type}
                  className={block.className}
                  placeholder={block.placeholder}
                  withToolbar={block.withToolbar}
                  toolbarOptions={block.toolbarOptions}
                  initialState={{
                    html: requiredBlocks[index].html,
                    time: requiredBlocks[index].params.time,
                  }}
                  onSave={onSaveRequiredBlock(index)}
                />
              )
            }

            return (
              <TextEditor
                key={index}
                type={block.type}
                className={block.className}
                placeholder={block.placeholder}
                withToolbar={block.withToolbar}
                withLettersCounter={block.withLettersCounter}
                toolbarOptions={block.toolbarOptions}
                initialState={requiredBlocks[index] || ''}
                onSave={onSaveRequiredBlock(index)}
                insertsAvailable={block.insertsAvailable}
                saveOnClickOutside={!validationEnabled}
              />
            )
          })}
        </div>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="optional-blocks">
            {(provided) => (
              <div className="editor-optional-blocks" {...provided.droppableProps} ref={provided.innerRef}>
                {optionalBlocks.map((block, index) => (
                  <>
                    <Draggable
                      key={block.keyForMap}
                      draggableId={block.keyForMap}
                      index={index}
                      isDragDisabled={nowChanging.includes(block.keyForMap)}
                    >
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                          {block.type === 'quote' && (
                          <QuoteEditor
                            placeholder={block.placeholder}
                            className={block.className}
                            withDeleteIcon
                            blockTypeIcon={block.iconType}
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            initialState={block}
                            opened={block.opened}
                          />
                          )}
                          {block.type === 'main-in-text' && (
                          <MainInTextEditor
                            placeholder={block.placeholder}
                            className={block.className}
                            withDeleteIcon
                            blockTypeIcon={block.iconType}
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            initialState={block}
                            opened={block.opened}
                          />
                          )}
                          {block.type === 'previously-by-theme' && (
                          <PreviouslyByThemeEditor
                            className={block.className}
                            withDeleteIcon
                            blockTypeIcon={block.iconType}
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            initialState={block}
                            opened={block.opened}
                          />
                          )}
                          {block.type === 'live' && (
                          <LiveTextEditor
                            type={block.type}
                            blockTypeIcon={block.iconType}
                            className={block.className}
                            placeholder={block.placeholder}
                            withToolbar={block.withToolbar}
                            withDeleteIcon
                            toolbarOptions={block.toolbarOptions}
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            initialState={{ html: block.html, params: block.params }}
                            opened={block.opened}
                          />
                          )}
                          {block.type === 'links' && (
                          <LinksEditor
                            className={block.className}
                            blockTypeIcon={block.iconType}
                            withDeleteIcon
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            initialState={block?.params?.links || []}
                            opened={block.opened}
                          />
                          )}
                          {block.type === 'image' && (
                          <MainPhotoEditor
                            type="main-photo"
                            className={block.className}
                            withDeleteIcon
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            initialState={block.params}
                            opened={block.opened}
                            withoutCrop
                          />
                          )}

                          {
                          block.type === 'embedded' && (
                            <EmbeddedEditor
                              className={block.className}
                              onSave={onSaveOptionalBlock(index)}
                              onDelete={() => onDeleteBlock(index)}
                              initialState={block.html}
                              withDeleteIcon
                              opened={block.opened}
                            />
                          )
                        }

                          {(block.type === 'text' || block.type === 'h2') && (
                          <TextEditor
                            type={block.type}
                            blockTypeIcon={block.iconType}
                            className={block.className}
                            placeholder={block.placeholder}
                            withToolbar={block.withToolbar}
                            initialState={block}
                            withDeleteIcon
                            toolbarOptions={block.toolbarOptions}
                            onDelete={() => onDeleteBlock(index)}
                            onSave={onSaveOptionalBlock(index)}
                            onChangeStart={() => onChangeStart(block.keyForMap)}
                            onChangeEnd={() => onChangeEnd(block.keyForMap)}
                            opened={block.opened}
                            insertsAvailable={block.insertsAvailable}
                          />
                          )}
                        </div>
                      )}
                    </Draggable>

                    <AddEditorBlock blocks={REDACTOR_CONFIG[type].optionalBlocks} onAdd={(block) => onAddOptionalBlock(index, block)} />
                  </>
                ))}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <RequiredFieldsNotification
          active={requiredNotificationActive}
          onClose={() => setRequiredNotificationActive(false)}
          type={type}
          data={{
            requiredBlocks,
            hot,
            inGrid,
            selectedTags,
            date,
            currentRubric,
            currentAuthors,
            description,
          }}
          isFastNews={!validationEnabled}
        />
      </BlocksBox>

      {!optionalBlocks.length && <AddEditorBlock blocks={REDACTOR_CONFIG[type].optionalBlocks} onAdd={(block) => onAddOptionalBlock(0, block)} />}

      {!validationEnabled
        && <div className="not-full-news">Новость дополняется...</div>}

      <RightPanel
        type={type}
        onShowPreview={() => togglePreview(true)}
        previewDisabled={previewDisabled}
        publishDisabled={publishDisabled}
        lettersCount={lettersCount}
        setCurrentRubric={setCurrentRubric}
        currentRubric={currentRubric}
        hot={hot}
        setHot={setHot}
        inGrid={inGrid}
        accessLinkOnly={accessLinkOnly}
        setAccessLinkOnly={setAccessLinkOnly}
        setInGrid={setInGrid}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        date={date}
        setDate={onDateChange}
        currentAuthors={currentAuthors}
        setAuthor={onAuthorSelect}
        setPseudonym={onPseudonymSelect}
        publish={publish}
        published={published}
        url={initialData.url}
        addCoAuthor={onAddCoAuthor}
        removeCoAuthor={removeCoAuthor}
        setDescription={setDescription}
        description={description}
        title={pageTitle}
        setTitle={setTitle}
        regenerate={regenerate}
        changed={changed}
        showRequiredNotification={() => setRequiredNotificationActive(true)}
        update={regenerate}
        validationEnabled={validationEnabled}
        toggleValidation={toggleValidation}
      />

      {previewShowed && (
        <ArticlePreview
          id={id}
          requiredBlocks={requiredBlocks}
          optionalBlocks={optionalBlocks}
          onClose={() => togglePreview(false)}
          rubricName={currentRubric.name}
          lettersCount={lettersCount}
          date={date}
          selectedTags={selectedTags}
          author={currentAuthors[0].author || authors.find((a) => a._id === currentAuthors[0].authorId)?.name}
          withPublish
          published={published}
          publish={publish}
          publishDisabled={publishDisabled}
          url={url}
          regenerate={regenerate}
          changed={changed}
          validationEnabled={validationEnabled}
        />
      )}
    </RedactorBox>
  )
}

const waitForData = (Component) => ({ match, type, ...props }) => {
  const { id } = match.params
  const [data, setData] = useState()

  useEffect(() => {
    async function loadData() {
      try {
        const response = await POSTS.getInfo(id)

        setData(response.data)
      } catch (error) {
        console.error(error)
      }
    }

    loadData()
  }, [id])

  if (!data) {
    return null
  }

  return (
    <Component
      match={match}
      type={type}
      {...props}
      initialData={data}
    />
  )
}

const enhanced = compose(
  withPageLayout,
  waitForData,
)(Redactor)

export { enhanced as Redactor }
