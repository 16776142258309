import styled from 'styled-components/macro'

import { FONTS } from 'constants/fonts'

export const RedactorBox = styled.div`
  padding: 50px 27px;

  .not-full-news {
    margin: 0 55px;
    border: 1px solid #897B6A;
    padding: 0 8px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Lato;
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    color: #1D1F24;
  }
`

export const BlocksBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;

  .left-prompt-wrapper {
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &.hidden {
      opacity: 0;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      background-image: linear-gradient(#b20000 50%, rgba(255, 255, 255, 0) 0%);
      background-position: left;
      background-size: 1px 10px;
      background-repeat: repeat-y;
      top: 0;
      left: 50%;
    }

    .prompt {
      min-width: 320px;
      height: 27px;
      box-sizing: border-box;
      transform: rotate(-90deg);
      padding: 0 57px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
      border: solid 1px #b20000;
      background-color: #ff9a9a;
      font-family: ${FONTS.roboto};
      font-size: 18px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.22;
      letter-spacing: -0.28px;
      text-align: center;
      color: white;
      text-transform: uppercase;
      z-index: 2;

      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 13px;
        left: -14px;
        top: 0;
        background: white;
        z-index: 2;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 13px;
        right: -14px;
        top: 0;
        background: white;
        z-index: 2;
      }
    }
  }

  .editor-main-blocks {
    margin-left: 27px;

    .title-editor {
      margin-bottom: 16px;
    }

    .text-editor {
      margin-top: 60px;
    }

    .main-photo-editor {
      margin-top: 20px;
    }
  }

  .editor-optional-blocks {
    padding-left: 54px;

    .previously-by-theme {
      margin-top: 40px;
    }

    .text-editor {
      margin-top: 40px;
    }

    .h2-editor {
      margin-top: 40px;
    }

    .quote-editor {
      margin-top: 40px;
    }

    .links-editor {
      margin-top: 40px;
    }

    .photo-editor {
      margin-top: 40px;
    }
    	
	  .change-photo.crop  { 
      display: none;
    }
    
    .change-photo  { 
      left: 343px;
    }
  }
`
