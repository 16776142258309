import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Editor, EditorState, RichUtils } from 'draft-js'
import 'draft-js/dist/Draft.css'
import classNames from 'classnames'

import { NotSelected } from 'ui/atoms'
import { Icon } from 'ui/atoms/Icon/index'
import { EditorToolbar } from './EditorToolbar'
import { withEditorContainer, Save } from './EditorContainer'
import { convertToHtml, convertFromHtml } from './convert'

const MainInTextEditorBox = styled.div`
  .text-source {
    font-style: italic;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    text-align: center;
    color: #707070;
  }

  .source-input-wrapper {
    display: flex;
    align-items: center;
  }

  .text-source-input {
    margin-bottom: 8px;
    box-sizing: border-box;
    margin-top: 8px;
    display: block;
    width: 300px;
    height: 36px;
    outline: none;
    border-radius: 4px;
    border: solid 2px #d5d5d8;
    background-color: white;
    padding-left: 8px;
    font-style: italic;
    font-stretch: normal;
    font-size: 14px;

    &::placeholder {
      opacity: 0.2;
      font-size: 14px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.21;
      letter-spacing: normal;
      text-align: left;
      color: black;
    }
  }
`

const MainInTextEditor = ({
  placeholder, active, editorRef, onSave, deactivate, initialState,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromHtml(initialState.html)))
  const [source, setSource] = useState(initialState?.params?.source || '')
  const [withSource, toggleSource] = useState(!!initialState?.params?.source)

  const onEditorChange = (state) => {
    setEditorState(state)
  }

  const toggleInlineStyle = (inlineStyle) => {
    onEditorChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const handleSourceToggleClick = () => {
    toggleSource((state) => !state)
  }

  const _onSave = () => {
    const html = convertToHtml(editorState.getCurrentContent())

    onSave({ html, params: { source: withSource ? source : '' } })

    deactivate()
  }

  const editorToolbarCustomContent = (
    <>
      <div
        className={classNames('option', { active: withSource })}
        onClick={handleSourceToggleClick}
      >
        <Icon type="author" />
      </div>
    </>
  )

  return (
    <>
      <MainInTextEditorBox>
        <Editor
          editorState={editorState}
          onChange={onEditorChange}
          readOnly={!active}
          placeholder={!active ? placeholder : null}
          ref={editorRef}
        />

        {active && (
          <EditorToolbar
            editorState={editorState}
            onToggle={toggleInlineStyle}
            availableOptions={[]}
            blockTypeIcon="main-in-text"
            customContent={editorToolbarCustomContent}
          />
        )}

        {active && withSource && (
          <div className="source-input-wrapper">
            <input
              className="text-source-input"
              placeholder="Источник"
              onChange={(e) => setSource(e.target.value)}
              value={source}
            />
            {!source && <NotSelected text="не указан" />}
          </div>
        )}

        {!active && withSource && (
          <div className="text-source">
            {source ? `${source}` : 'Источник'}
          </div>
        )}
      </MainInTextEditorBox>

      <Save onSave={_onSave} active={active} />
    </>
  )
}

const enhanced = withEditorContainer(MainInTextEditor, 'main-in-text')

export { enhanced as MainInTextEditor }
